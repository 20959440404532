import React from "react"
import { MdFileDownload, MdPlayCircleOutline } from "react-icons/md"
import { FaFreeCodeCamp } from "react-icons/fa"
import Heading from "../components/heading"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./resume.scss"

const headingClassNamesForHeader =
  "is-size-4 has-text-weight-semibold my-3 has-text-centered-mobile"
const headingClassNamesForSkills =
  "is-size-5 has-text-weight-semibold my-3 has-text-centered-mobile"

const SkillTag = label => (
  <span key={label} className="tag is-link is-light is-rounded">{label}</span>
)

const Experience = () => {
  return (
    <>
      <Heading className={headingClassNamesForHeader}>Experience</Heading>
      <div className="timeline">
        {/* <header className="timeline-header">
        <span className="tag is-medium is-primary">Experience</span>
      </header> */}
        <div className="timeline-item is-danger">
          <div className="timeline-marker is-icon is-size-4">
            {/* <img
              src="https://bulma.io/images/placeholders/32x32.png"
              alt="active-timeline-icon"
            /> */}
            <MdPlayCircleOutline size="3rem" />
          </div>

          <div className="timeline-content">
            <p className="heading">2021 – Current</p>
            <p className="is-size-5 has-text-danger-dark">Wells Fargo</p>
            <p>
              Solving real world problems at one of the top financial services company.
            </p>
          </div>
        </div>
        <div className="timeline-item is-danger">
          <div className="timeline-marker">
          </div>

          <div className="timeline-content">
            <p className="heading">2017 – 2021</p>
            <p className="is-size-5 has-text-danger-dark">Galaxe Solutions</p>
            <p>
              Worked as Full Stack Developer for developing applications using
              micro-frontend and micro-service architecture. Responsible for End
              to End Product initiation and deployment. Tech Used: React, Redux,
              Axios, Spring Boot, Spring Batch, PostgreSQL, Grafana, Jenkins,
              Git, and PCF.
            </p>
          </div>
        </div>
        <div className="timeline-item is-danger">
          <div className="timeline-marker"></div>
          <div className="timeline-content">
            <p className="heading">2014 - 2016</p>
            <p className="is-size-5 has-text-danger-dark">Tata Consultancy Services</p>
            <p>
              Worked as Module Lead. Developed the foundation architecture for
              project migration to new tech stack with Spring Boot and AngularJS
              from J2EE, JPA JSF based project. Tech Used: JSF1.2, JPA, DAO,
              Alfresco, IBM WPS, HP Checkmarx, SonarQube, Spring Boot, and
              Angular
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const Education = () => {
  return (
    <>
      <Heading className={headingClassNamesForHeader}>Education</Heading>
      <div className="timeline">
        {/* <header className="timeline-header">
        <span className="tag is-medium is-primary">Education</span>
      </header> */}
        <div className="timeline-item is-danger">
          <div className="timeline-marker"></div>

          <div className="timeline-content">
            <p className="heading">2020</p>
            <p className="is-size-5 has-text-danger-dark">freeCodeCamp</p>
            <p>Front End Libraries Computer Software Engineering</p>
            <p>
              Free Code Camp's Front End Libraries program covers an estimated
              300 hours of course work that includes CSS3, Sass, Bootstrap,
              JQuery, React and Redux to make responsive and dynamic websites.
            </p>
          </div>
        </div>
        <div className="timeline-item is-danger">
          <div className="timeline-marker"></div>
          <div className="timeline-content">
            <p className="heading">2014</p>
            <p className="is-size-5 has-text-danger-dark">SRM University</p>
            <p>School of Computing</p>
            <p>
              Bachelor of Technology (B-Tech), Computer Science Engineering. GPA
              8.9/10.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const Skills = () => {
  return (
    <div className="is-flex is-flex-direction-column m-4">
      <div className="block">
        <Heading className={headingClassNamesForSkills}>Frontend</Heading>
        <div className="tags are-medium">
          {["React", "Redux", "HTML", "CSS", "Javascript", "Angular"].map(
            SkillTag
          )}
        </div>
      </div>
      <div className="block">
        <Heading className={headingClassNamesForSkills}>Backend</Heading>
        <div className="tags are-medium">
          {["Java", "SpringBoot", "Hibernate", "NodeJS"].map(SkillTag)}
        </div>
      </div>
      <div className="block">
        <Heading className={headingClassNamesForSkills}>Mobile/App</Heading>
        <div className="tags are-medium">{["Flutter"].map(SkillTag)}</div>
      </div>
      <div className="block">
        <Heading className={headingClassNamesForSkills}>CMS</Heading>
        <div className="tags are-medium">{["Wordpress"].map(SkillTag)}</div>
      </div>
      <div className="block">
        <Heading className={headingClassNamesForSkills}>Cloud</Heading>
        <div className="tags are-medium">
          {["Google Cloud", "Oracle Cloud", "Netlify"].map(SkillTag)}
        </div>
      </div>
    </div>
  )
}

const Certificates = () => {
  return (
    <div className="m-4">
      <Heading className={headingClassNamesForHeader}>Certificates</Heading>
      <div className="columns is-multiline">
        <div className="column is-full">
        <div className="card">
        <div className="card-content">
          <div className="media">
            <figure className="media-left">
              <p className="image is-96x96">
                <img src="https://images.credly.com/size/340x340/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png" alt="Microsoft Certified: Azure Fundamentals" />
              </p>
            </figure>
            <div className="media-content">
              <p className="title is-4">Microsoft</p>
              <p className="subtitle is-6">Microsoft Certified: Azure Fundamentals</p>
              <a
                href="https://www.credly.com/badges/32401ff6-d549-4b95-9d1a-5942fa627d43"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credentials
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div className="column">
        <div className="card">
        <div className="card-content">
          <div className="media">
            <figure className="media-left">
              <p className="image is-96x96">
                <FaFreeCodeCamp size="96px" />
              </p>
            </figure>
            <div className="media-content">
              <p className="title is-4">Free Code Camp</p>
              <p className="subtitle is-6">Front End Libraries</p>
              <a
                href="https://www.freecodecamp.org/certification/shi2rsingh/front-end-libraries"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credentials
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
      
      
    </div>
  )
}

const Presentations = () => {
  return (
    <div className="m-4">
      <Heading className={headingClassNamesForHeader}>Talks</Heading>
      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-4">GalaxE Solutions</p>
              <p className="subtitle is-6">React and Redux </p>
              <a
                href="https://decks.shishirsingh.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Presentation Link
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Resume = props => {
  return (
    <Layout>
      <Seo title="Shishir Singh Resume | Shishir Singh CV" />
      <div className="container">
        <section className="section" id="resume">
          <div className="section-heading has-text-centered">
            <h3 className="title is-2">Resume</h3>
            <h4 className="subtitle is-5">More about my past</h4>
            <a
              role="button"
              href="/documents/ShishirSingh_CV.pdf"
              download="ShishirSingh_CV.pdf"
              className="button is-link is-medium"
            >
              <span className="icon">
                <MdFileDownload />
              </span>
              <span>Download My Resume</span>
            </a>
          </div>
        </section>
        <div className="section pt-0">
          <div className="columns">
            <div className="column is-half">
              <Experience />
              <Education />
            </div>
            <div className="column is-half">
              <Skills />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Certificates />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Presentations />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Resume
